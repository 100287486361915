import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./tailwind.css"
import BlogList from "../components/blog_list"

const ArticlesPage = ({ data }) => (
  <Layout>
    <SEO title="Articles" />
    <BlogList color="bg-white" title="All Articles" description="Enjoy the full archive of posts on Production Ready GraphQL" posts={data.allMarkdownRemark.edges} />
  </Layout>
)

export const query = graphql`
  query ArticlesList {
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, limit: 100) {
      edges {
        node {
          postImage {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          frontmatter {
            title
            path
            date
            tag
            author
            description
            imageUrl
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`

export default ArticlesPage
